import React from "react"
import styled from "styled-components"

const DividerLine = ({ margin = "0 0 20px", className = "" }) => {
  return (
    <div className={className} style={{ margin: margin }}>
      <SectionUnderline />
    </div>
  )
}

export default DividerLine

// ===============
//     STYLES
// ===============
const SectionUnderline = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.lightgrey3};
`
