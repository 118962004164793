import React from "react"
import styled from "styled-components"
import CloudflareImage from "../reusable-components/cloudflare-image"

import LinkWrapper from "../../components/reusable-components/link-wrapper"
import BlogMetaData from "../../components/blog-components/blog-meta-data"

import { getProperty } from "../../lib/utils"

const RelatedArticleCard = ({ post, featuredImage, className = "" }) => {
  const relatedArticleTitle = getProperty(post, ["title"], "")
  const datum = getProperty(post, ["date"], "")
  const slug = getProperty(post, ["slug"], "")
  return (
    <CardStyling className={className}>
      {!featuredImage ? null : (
        <StyledImg objectFit="cover" src={featuredImage} />
      )}

      <StyledReadArticleLinkWrapper
        to={`/${process.env.GATSBY_BLOG_PAGE_SLUG}/${slug}/`}
      >
        Read article
      </StyledReadArticleLinkWrapper>

      <BlogDataStyling className="data-hover-effect">
        <LinkWrapper to={`/${process.env.GATSBY_BLOG_PAGE_SLUG}/${slug}/`}>
          <h5 dangerouslySetInnerHTML={{ __html: relatedArticleTitle }} />
        </LinkWrapper>
        <BlogMetaData datum={datum} />
      </BlogDataStyling>
    </CardStyling>
  )
}

export default RelatedArticleCard

// ===============
//     STYLES
// ===============
const CardStyling = styled.div`
  width: 100%;
  position: relative;
  .data-hover-effect {
    position: absolute;
    width: 100%;
    bottom: 0;
    transition: all 0.3s ease-in-out;
  }
  &:hover .data-hover-effect {
    bottom: 64px;
    transition: all 0.3s ease-in-out;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    width: 48%;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100%;
  }
`
const BlogDataStyling = styled.div`
  z-index: 50;
  background-color: white;
  padding: 15px 20px;
  h5 {
    padding-bottom: 20px;
    text-align: left;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    padding: 15px 0px;
  }
`
const StyledReadArticleLinkWrapper = styled(LinkWrapper)`
  background-color: ${({ theme }) => theme.colors.lightgrey3};
  display: block;
  color: ${({ theme }) => theme.colors.black1};
  text-align: center;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
  border-radius: 50px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey};
    color: ${({ theme }) => theme.colors.white};
  }
`
const StyledImg = styled(CloudflareImage)`
  height: 400px;
  overflow: hidden;
  img {
    left: unset;
    right: 0;
  }
  & div {
    padding-bottom: 100% !important;
  }
`
