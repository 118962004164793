import React from "react"
import styled from "styled-components"

import FlexSection from "../../components/reusable-components/layout/flex-section"

import FacebookIcon from "../../images/icons/facebook"
import TwitterIcon from "../../images/icons/twitter"
import LinkedInIcon from "../../images/icons/linkedin"
import MailIcon from "../../images/icons/solidmail"
import WhatsAppIcon from "../../images/icons/whatsapp"

const socialButtons = [
  {
    Icon: TwitterIcon,
    generateLink: (url) => `https://twitter.com/intent/tweet?text=${url}`,
  },
  {
    Icon: FacebookIcon,
    generateLink: (url) =>
      `https://www.facebook.com/sharer/sharer.php?u=${url}`,
  },
  {
    Icon: LinkedInIcon,
    generateLink: (url) =>
      `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
  },
  {
    Icon: MailIcon,
    generateLink: (url, type) =>
      `mailto:?subject=I wanted you to see this ${process.env.GATSBY_WEBSITE_TITLE} ${type}&body=Check out this ${process.env.GATSBY_WEBSITE_TITLE} ${type} ${url}`,
  },
  {
    Icon: WhatsAppIcon,
    generateLink: (url, type) =>
      `https://wa.me/?text=Check out this ${process.env.GATSBY_WEBSITE_TITLE} ${type} ${url}`,
  },
]

const ShareButtons = ({ sharingUrl, type = "Page" }) => {
  return (
    <SharebuttonFlexStyling direction="row" margin={"0 0 0"}>
      {socialButtons.map(({ Icon, generateLink }, i) => (
        <SocialShareButtonsLinkStyling
          key={i}
          href={generateLink(encodeURIComponent(sharingUrl), type)}
          target="_blank"
          rel="noreferrer noopener"
        >
          <Icon />
        </SocialShareButtonsLinkStyling>
      ))}
    </SharebuttonFlexStyling>
  )
}

// ===============
//     STYLES
// ===============
const SocialShareButtonsLinkStyling = styled.a`
  display: block;
  margin-right: 30px;
  margin-top: 20px;
  svg,
  svg path {
    fill: ${({ theme }) => theme.colors.blue};
    vertical-align: bottom;
  }
  svg g path,
  svg g {
    stroke: ${({ theme }) => theme.colors.blue};
    fill: white;
  }
  @media (min-width: ${({ theme }) => theme.breakMedium}) {
    &:last-child {
      display: none;
    }
  }
`
const SharebuttonFlexStyling = styled(FlexSection)`
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: row;
  }
`
export default ShareButtons
