import React from "react"
import styled from "styled-components"

import DividerLine from "../../components/reusable-components/divider-line"
import LinkWrapper from "../../components/reusable-components/link-wrapper"

import { getProperty } from "../../lib/utils"

const BlogCategories = ({ pageContext }) => {
  const categoryPostTotals = getProperty(
    pageContext,
    ["categoryPostTotals"],
    {}
  )
  const categoryPageUrls = getProperty(pageContext, ["categoryPageUrls"], {})

  return (
    <CatTotalContainerStyling>
      <h2 className="light">Blog Categories</h2>
      <DividerLine margin="0 0 20px" />
      {Object.entries(categoryPageUrls).map(([cat, url], index) => {
        const count = getProperty(categoryPostTotals, [cat], "")
        return (
          <CategoryPostTotal key={index}>
            <LinkWrapper to={`${url}`}>
              <span>{cat}</span>
              <span>{!count ? null : `(${count})`}</span>
            </LinkWrapper>
          </CategoryPostTotal>
        )
      })}
    </CatTotalContainerStyling>
  )
}

export default BlogCategories

// ===============
//     STYLES
// ===============
const CatTotalContainerStyling = styled.div`
  margin: 0 0 3rem;
  width: 100%;
`
const CategoryPostTotal = styled.div`
  width: 100%;
  a {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`
