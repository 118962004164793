import React from "react"
import { useState } from "react"
import styled from "styled-components"

const CloudflareImage = ({
  src = "",
  alt = "Cloudflare image",
  className,
  objectFit = "fill",
  objectPosition = "50% 50%",
}) => {
  if (!src) {
    src = ""
  }

  const [error, setError] = useState(false)

  const sizes = ["325", "430", "717", "964", "1024", "1920"]

  const webpImages = sizes.map((size) => `${src} ${size}w`)
  const fallbackImages = sizes.map((size) => `${src} ${size}w`)

  return error ? null : (
    <CloudflareImageWrapper className={`cloudflare-image-wrapper ${className}`}>
      <picture className="cloudflare-image-picture">
        <source type="image/webp" srcSet={webpImages.join()} />
        <CloudflareImgStyled
          sizes="(min-width: 800px) 800px, 100vw"
          alt={alt}
          decoding="async"
          loading="lazy"
          src={src}
          srcSet={fallbackImages.join()}
          className="cloudflare-image-img"
          objectFit={objectFit}
          objectPosition={objectPosition}
          onError={() => {
            console.error(
              "CloudflareImage Error: The supplied src is not valid! ",
              src
            )
            setError(true)
          }}
        />
      </picture>
    </CloudflareImageWrapper>
  )
}

export default CloudflareImage

const CloudflareImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
`

const CloudflareImgStyled = styled.img`
  height: 100%;
  width: 100%;
  margin: 0;
  object-fit: ${({ objectFit }) => objectFit};
  object-position: ${({ objectPosition }) => objectPosition};
`
