import React from "react"
import styled from "styled-components"

const FlexSection = ({
  direction = "column",
  width = "100%",
  align = "flex-start",
  justify = "flex-start",
  padding = "0 0px",
  margin = "0 auto 6rem",
  wrap = "inherit",
  className = "", //optional
  children, //required
}) => {
  return (
    <FlexContainer
      direction={direction}
      className={className}
      align={align}
      justify={justify}
      padding={padding}
      margin={margin}
      width={width}
      wrap={wrap}
    >
      {children}
    </FlexContainer>
  )
}

export default FlexSection

// ===============
//     STYLES
// ===============
const FlexContainer = styled.div`
  display: flex;
  position: relative;
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  flex-direction: ${({ direction }) => direction};
  flex-wrap: ${({ wrap }) => wrap};
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: column;
    margin: 0 auto 3rem;
    & > *.full-width-inner-section {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      margin: 0 0 3rem;
    }
  }
`
