import React from "react"
import styled from "styled-components"

const BlogMetaData = ({
  datum,
  className = "", //optional
}) => {
  return (
    <BlogPostMetaDataStyling className={className}>
      {`by ${process.env.GATSBY_WEBSITE_TITLE}`}
      <Dot />
      {datum}
    </BlogPostMetaDataStyling>
  )
}

export default BlogMetaData

const Dot = styled.span`
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.blue};
  border-radius: 100%;
  margin: auto 10px;
`
const BlogPostMetaDataStyling = styled.div`
  display: flex;
  align-items: center;
  font-style: italic;
  flex-wrap: wrap;
  &.blue-text {
    color: ${({ theme }) => theme.colors.blue};
  }
`
