import React from "react"
import styled from "styled-components"

import FlexSection from "../../components/reusable-components/layout/flex-section"
import DividerLine from "../../components/reusable-components/divider-line"
import RelatedArticleCard from "../../components/blog-components/related-article-card"

import { getProperty } from "../../lib/utils"

const RelatedArticles = ({ pageContext, data }) => {
  const relatedPosts = getProperty(pageContext, ["relatedPosts"], {})
  const featImgNodes = getProperty(data, ["allWpMediaItem", "nodes"], [])

  return (
    <RelatedArticlesFlexStyling margin="0 0 3rem" wrap="wrap">
      <h2 className="light">Related Articles</h2>
      <DividerLine margin="0 0 30px" />
      {relatedPosts.map((post, index) => {
        // filter the featImgNodes to only include current databaseId from the map
        const featImgNode = featImgNodes.filter(
          (node) => node.databaseId == post.featuredImageDatabaseId
        )
        const featuredImage =
          featImgNode.length &&
          getProperty(featImgNode[0], ["sourceUrl"], false)
        return (
          <RelatedArticleCard
            key={index}
            post={post}
            featuredImage={featuredImage}
          />
        )
      })}
    </RelatedArticlesFlexStyling>
  )
}

export default RelatedArticles

// ===============
//     STYLES
// ===============
const RelatedArticlesFlexStyling = styled(FlexSection)`
  & > * {
    width: 100%;
  }
  & > div:not(:last-child) {
    margin: 0 0 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: row;
    justify-content: space-between;
  }
`
