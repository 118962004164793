import React from "react"
import styled from "styled-components"

import DividerLine from "../../components/reusable-components/divider-line"

import { getProperty } from "../../lib/utils"

const CurrentBlogTags = ({ pageContext }) => {
  const tags = getProperty(pageContext, ["tags"], "")

  return (
    <TagBoxStyling>
      <h2 className="light">Tags</h2>
      <DividerLine margin="0 0 20px" />
      <TagStyling>{tags.join(", ")}</TagStyling>
    </TagBoxStyling>
  )
}

export default CurrentBlogTags

// ===============
//     STYLES
// ===============
const TagStyling = styled.div`
  span {
    display: inline-block;
    width: auto;
    margin-right: 10px;
  }
`
const TagBoxStyling = styled.div`
  width: 100%;
`
