import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import CloudflareImage from "../../components/reusable-components/cloudflare-image"
// import * as DOMPurify from "dompurify"

import Layout from "../../components/theme/layout"
import SEO from "../../components/theme/header/seo"
import FlexSection from "../../components/reusable-components/layout/flex-section"
import DividerLine from "../../components/reusable-components/divider-line"

import RelatedArticles from "../../components/blog-components/related-articles"
import BlogCategories from "../../components/blog-components/blog-categories"
import BlogMetaData from "../../components/blog-components/blog-meta-data"
import CurrentBlogTags from "../../components/blog-components/current-blog-tags"
import ShareButtons from "../../components/reusable-components/share-buttons"

import { getProperty } from "../../lib/utils"

const BlogPostPage = ({ pageContext, data, location }) => {
  // const title = DOMPurify.sanitize(getProperty(pageContext, ["title"], ""))
  const title = getProperty(pageContext, ["title"], "")
  // const content = DOMPurify.sanitize(
  //   getProperty(data, ["wpPost", "content"], "")
  // ) // sanitizing the html to protect against XSS attacks
  const content = getProperty(data, ["wpPost", "content"], "")
  const datum = getProperty(pageContext, ["date"], "")
  const featuredImage = getProperty(
    data,
    ["wpPost", "featuredImage", "node", "sourceUrl"],
    false
  )
  let siteUrlSharing = ""
  if (typeof window != "undefined" && window.location) {
    siteUrlSharing = window.location.href
  }

  return (
    <Layout pathContext={pageContext} location={location}>
      <SEO
        title={getProperty(data, ["seaBlog", "seo", "title"], "")}
        location={location}
        yoast={getProperty(data, ["seaBlog", "seo"], {})}
      />
      <FlexSection direction="row" justify="space-between" wrap="wrap">
        <BlogBodyStyling className="full-width-inner-section">
          <h1 dangerouslySetInnerHTML={{ __html: title }} />
          <BlogMetaData datum={datum} />
          <FlexSection direction="row" align="center">
            <StyledImg objectFit="cover" src={featuredImage} />
            <p style={{ width: "50%", marginLeft: "20px" }}>
              Euro Bath and Tile offers an expansive range of luxurious bathroom
              and tile products that cater to a range of tastes and styles,
              whether you are looking for contemporary or artisanal designs.
            </p>
          </FlexSection>
          <BlogContentStyling dangerouslySetInnerHTML={{ __html: content }} />
        </BlogBodyStyling>
        <BlogSideBarStyling
          justify="flex-end"
          align="flex-end"
          className="full-width-inner-section"
          margin="0 0 0"
        >
          <RelatedArticles pageContext={pageContext} data={data} />
          <BlogCategories pageContext={pageContext} />
          <CurrentBlogTags pageContext={pageContext} />
        </BlogSideBarStyling>
      </FlexSection>
      <DividerLine margin="4rem auto 3rem" />
      <h4>Share</h4>
      <ShareButtons sharingUrl={siteUrlSharing} type={"Article"} />
      <DividerLine margin="4rem auto 3rem" />
    </Layout>
  )
}

export default BlogPostPage

// ===============
//     STYLES
// ===============
const BlogContentStyling = styled.div`
  h2 {
    font-size: 1.777rem;
    color: ${({ theme }) => theme.colors.darkgrey};
    font-weight: 400;
    padding: 0.5rem 0 0rem;
  }
  h3 {
    font-size: 1.777rem;
    color: ${({ theme }) => theme.colors.black1};
    font-weight: 700;
    padding: 0.5rem 0 0rem;
  }
  h4 {
    font-size: 1.333rem;
    color: ${({ theme }) => theme.colors.grey};
    font-weight: 400;
  }
  blockquote {
    margin: 50px 0;
    padding: 30px 50px 50px;
    background-color: ${({ theme }) => theme.colors.lightgrey2};
    text-align: center;
    cite {
      font-size: 1.333rem;
      font-weight: 700;
      line-height: 2rem;
    }
  }
  figure,
  figure img {
    height: 90%;
    text-align: center;
  }

  figure {
    margin: 50px 0;
  }

  video {
    width: 100%;
  }

  .wp-block-gallery {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 36px;
    margin-bottom: 100px;
  }

  .wp-block-gallery figure {
    margin: 0;
  }

  .wp-block-image img,
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: center;
    margin-bottom: 0px;
  }

  @media screen and (max-width: 767px) {
    .wp-block-gallery {
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px;
      margin: 100px 0px;
    }

    figure,
    figure img {
      height: 70%;
    }
  }
`
const BlogBodyStyling = styled.div`
  width: 71%;
`
const BlogSideBarStyling = styled(FlexSection)`
  width: 25%;
`
const StyledImg = styled(CloudflareImage)`
  height: 360px;
  width: 50% !important;
  aspect-ratio: 1/1;
  // margin: 50px 0;
`

// ===============
//      QUERY
// ===============
export const blogPostQueryData = graphql`
  query GET_BLOG_POST_QUERY_DATA(
    $databaseId: Int!
    $relatedPostImageIds: [Int]
  ) {
    wpPost(databaseId: { eq: $databaseId }) {
      id
      databaseId
      title
      slug
      content
      featuredImage {
        node {
          sourceUrl
        }
      }
    }
    allWpMediaItem(filter: { databaseId: { in: $relatedPostImageIds } }) {
      nodes {
        databaseId
        sourceUrl
      }
    }
    seaBlog(database_id: { eq: $databaseId }) {
      seo {
        description
        meta_description
        og_description
        og_image
        og_title
        og_type
        site_name
        title
        twitter_card
        twitter_creator
        twitter_description
        twitter_title
      }
    }
  }
`
